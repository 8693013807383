import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useMemo, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Model from '../model';
import { error, success } from '../Toast';
import SkeletonLoader from '../SkeletonLoader';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import ConfirmationDialog from '../deleteModal';
import { paginationRowsPerPage } from '../../const/strings';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '400px',
    maxWidth: '90%',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function GroupsChild() {
  const state = useSelector((state: any) => state.config);
  const orgId = useMemo(() => state.orgId, [state.orgId]); // Memoize orgId
  const [rows, setGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [editGroupId, setEditGroupId] = useState('');
  const [editGroup, setEditGroup] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const [parentGroupId, setParentGroupId] = useState('');
  const [childGroupName, setChildGroupName] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const initialRender = useRef(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId]: any = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const { clientid } = useParams();
  const [count, setCount] = useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  const [openModal, setOpenModal] = useState(false);
  // Table data code starts

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const handleOpenConfirmation = (index: any, userId: any) => {
    setSelectedUserId(userId);
    setSelectedRowIndex(index);
    setOpenConfirmation(true);
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };

  const fetchData = async (query = '') => {
    try {
      const url = query
        ? `admin/realms/${orgId}/groups/${clientid}/children?first=${firstValue}&max=${rowsPerPage!}&search=${query}&global=false`
        : `admin/realms/${orgId}/groups/${clientid}/children?first=${firstValue}&max=${rowsPerPage!}`;
      const paginationUrl = query
        ? `admin/realms/${orgId}/groups/${clientid}/children?search=${query}`
        : `admin/realms/${orgId}/groups/${clientid}/children`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      setCount(paginationResponse.length);
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      if (apiData) {
        setGroups(apiData);
      } else {
        console.error('API returned undefined data');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  function createData(SNo: number, id: string, Roles: string, path: any, subGroup: any, name: any) {
    return { SNo, id, Roles, path, subGroup, name };
  }

  const handleDeleteRow = async (index: number, id: string) => {
    try {
      const url = `/admin/realms/${orgId}/groups/${id}`;
      const response = await ApiHttpClient.delete(url, {});
      console.log(response, 'response');

      if (response === '') {
        fetchData();
        console.log('group deleted sucessfully');
        success('Group Deleted Sucessfully');
        setOpenConfirmation(false);
      } else {
        console.error('Failed to delete group:', response.statusText);
        error('Failed to delete group');
        setOpenConfirmation(false);
      }
    } catch (err) {
      console.error('Error deleting group:', err);
      error('Error deleting group');
      setOpenConfirmation(false);
    }
  };

  const handleUpdateGroup = async () => {
    try {
      const url = `/admin/realms/${orgId}/groups/${editGroupId}`;
      const payload = { ...editGroup, name: groupName };
      const response = await ApiHttpClient.put(url, payload);
      if (response === '') {
        success('Group updated successfully');
        console.log('Group updated successfully');
        fetchData('');
        handleClose();
      } else {
        handleClose();
        console.error('Failed to update group:', response.statusText);
        error('Failed to update group');
      }
    } catch (err) {
      handleClose();
      console.error('Error updating group:', err);
      error('Error updating group');
    }
  };

  // Table data code ends

  // Modal pop up code starts
  const handleClickOpen = () => {
    setEditGroupId('');
    setGroupName('');
    setIsEditMode(false);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setGroupName('');
    setEditGroupId('');
    setIsEditMode(false);
  };

  const handleEditOpen = (id: string, name: string, row: any) => {
    setEditGroupId(id);
    setGroupName(name);
    setIsEditMode(true);
    setEditGroup(row);
    setOpenModal(true);
  };

  const handleCreateGroup = async () => {
    try {
      const url = `admin/realms/${orgId}/groups/${clientid}/children`;
      const payload = { name: groupName };
      const response = await ApiHttpClient.post(url, payload);
      if (response.status === 200) {
        success('Group Created Sucessfully');
        fetchData();
        setGroupName('');
        handleClose();
        setSearchTerm('');
      } else {
        handleClose();
        console.log('@@@@@');

        error('Failed to create group');
        console.error('Failed to create group:', response.statusText);
      }
    } catch (err: any) {
      console.error('Error creating group:', err);
      error(err?.['response']?.data || 'Error creating group');
    }
  };

  const handleCreateChildren = (id: string) => {
    setParentGroupId(id);
    setOpenChild(true);
  };

  const handleCloseChild = () => {
    setOpenChild(false);
    setChildGroupName('');
  };
  const handleCreateChildGroup = async () => {
    try {
      const url = `admin/realms/${orgId}/groups/${parentGroupId}/children`;
      const payload = { name: childGroupName };
      const response = await ApiHttpClient.post(url, payload);
      if (response.status === 200) {
        fetchData('');
        setChildGroupName('');
        handleCloseChild();
        success('Child Group created successfully');
      } else {
        console.error('Failed to create child group:', response.statusText);
        error('Failed to create child group');
      }
    } catch (err) {
      console.error('Error creating child group:', err);
      error('Error creating child group');
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };
  // Modal pop up code Ends

  return (
    <>
      {rows && rows.length > 0 ? (
        <Grid item xs={12}>
          {loading ? (
            <SkeletonLoader rows={''} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  value={searchTerm}
                  label="Search Group"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleSearchInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} textAlign="right">
                <Button size="medium" variant="contained" onClick={handleClickOpen}>
                  Create Child Group
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>S.No </StyledTableCell>
                        <StyledTableCell sx={{ cursor: 'pointer' }}>Groups </StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row: any, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>

                          <StyledTableCell>
                            {/* <Typography
                              sx={{ cursor: 'pointer', marginRight: '10px' }}
                              component="span"
                              color={'red'}
                              onClick={() => handleOpenConfirmation(index, row.id)}
                            >
                              <DeleteIcon />
                            </Typography> */}
                            <Tooltip title="Rename Group">
                              <Typography
                                sx={{ cursor: 'pointer', marginRight: '10px' }}
                                component="span"
                                color={'primary'}
                                onClick={() => handleEditOpen(row.id, row.name, row)}
                              >
                                <EditIcon />
                              </Typography>
                            </Tooltip>
                            {/* <Tooltip title="Move Group to">
                          <Typography
                            sx={{ cursor: 'pointer', marginRight: '10px' }}
                            component="span"
                            color={'primary'}
                          >
                            <DriveFileMoveIcon />
                          </Typography>
                        </Tooltip> */}
                            {/* <Tooltip title="Create Child Group">
                          <Typography
                            sx={{ cursor: 'pointer', marginRight: '10px' }}
                            component="span"
                            color={'primary'}
                            onClick={() => handleCreateChildren(row.id)}
                          >
                            <GroupAddIcon />
                          </Typography>
                        </Tooltip> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Groups per Page'}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: 2,
          }}
        >
          <Grid item xs={12} sm={6}>
            <>
              <Typography variant="body1" gutterBottom>
                No groups in this sub group
              </Typography>
              <Typography variant="body2" gutterBottom>
                You haven't created any groups in this sub group.
              </Typography>
            </>
          </Grid>
          <Grid item xs={12} sm={6} textAlign="center">
            <Button size="medium" variant="contained" onClick={handleClickOpen}>
              Create Child Group
            </Button>
          </Grid>
        </Box>
      )}

      <Model
        open={openModal}
        handeleConfirm={isEditMode ? handleUpdateGroup : handleCreateGroup}
        title={isEditMode ? 'Update Group' : 'Create Group'}
        saveButtonName={isEditMode ? 'Update Group' : 'Create Group'}
        data-modal-backdrop="static"
        height="auto"
        cancelClick={() => handleClose()}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Group Name"
              id="outlined"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Grid>
        </Grid>
      </Model>

      <BootstrapDialog onClose={handleCloseChild} open={openChild}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Create Child Group
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseChild}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Child Group Name"
                id="outlined"
                value={childGroupName}
                onChange={(e) => setChildGroupName(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="medium" variant="contained" autoFocus onClick={handleCloseChild}>
            Cancel
          </Button>
          <Button size="medium" variant="contained" autoFocus onClick={handleCreateChildGroup}>
            Create Child Group
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        title="Confirm Deletion"
        content="Are you sure you want to delete this Group?"
        onConfirm={() => handleDeleteRow(selectedRowIndex, selectedUserId)}
      />
    </>
  );
}

export default GroupsChild;
