import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import Layout from '../Layout';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useParams } from 'react-router-dom';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { error, success } from '../Toast';
import Model from '../model';
import SkeletonLoader from '../SkeletonLoader';
import ConfirmationDialog from '../deleteModal';
import TablePagination from '@mui/material/TablePagination';
import { paginationRowsPerPage } from '../../const/strings';
import { setConfigSlice } from '../../store/realmSlice';

function Roles() {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [rows, setRoles] = useState([]);
  const { orgName, clientId } = useParams();
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const initialRender = useRef(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [count, setCount] = useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  // Table data code starts

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const handleOpenConfirmation = (userId: any) => {
    setSelectedUserId(userId);
    setOpenConfirmation(true);
  };

  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = query
        ? `/admin/realms/${orgId}/roles?first=${firstValue}&max=${rowsPerPage!}&search=${query}`
        : `/admin/realms/${orgId}/roles?first=${firstValue}&max=${rowsPerPage}`;
      const paginationUrl = query ? `/admin/realms/${orgId}/roles?search=${query}` : `/admin/realms/${orgId}/roles`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      setCount(paginationResponse.length);
      const response = await ApiHttpClient.get(url);
      let apiData: any = response;
      if (!Array.isArray(apiData)) {
        apiData = [apiData];
      }
      if (apiData) {
        const formattedRows = apiData.map((role: any, index: any) => createData(index + 1, role.name));
        setRoles(formattedRows);
      } else {
        console.error('API returned undefined data');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };
  function createData(SNo: number, Roles: string) {
    return { SNo, Roles };
  }

  // Table data code ends

  // Modal pop up code starts
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
    setOpenModal(true);
    setRoleName('');
    setDisableButton(true);
    setOpen(true);
  };
  const handleClose = () => {
    // setOpen(false);/
    setRoleName('');
    setRoleDescription('');
    setOpenModal(false);
  };

  const handleCreateRole = async () => {
    try {
      if (!roleName.trim()) {
        error('Role name is required');
        return;
      }

      const url = `/admin/realms/${orgId}/roles`;
      const roleData = { name: roleName, description: roleDescription };
      await ApiHttpClient.post(url, roleData);
      setRoleName('');
      setRoleDescription('');
      fetchData('');
      handleClose();
      setSearchTerm('');
      success('Role created successfully');
    } catch (err) {
      console.error('Error creating role:', err);
      error('Error creating role');
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const handleDeleteRow = async (roleName: any) => {
    try {
      const url = `admin/realms/${orgName}/roles/${roleName}`;
      await ApiHttpClient.delete(url, {});
      success('User deleted successfully');
      fetchData('');
      setOpenConfirmation(false);
      setSearchTerm('');
    } catch (err) {
      console.error('Error deleting user:', err);
      error('Error deleting user:');
      setOpenConfirmation(false);
    }
  };

  const handleInputChange = (e: any) => {
    let inputValue = e.target.value;
    inputValue.trim() !== '' ? setDisableButton(false) : setDisableButton(true);
    setRoleName(inputValue.trim());
  };

  // Modal pop up code Ends

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          {loading ? (
            <SkeletonLoader rows={''} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={9}>
                <Typography variant="h6">Roles</Typography>
                <Typography variant="body1">
                  <Typography component="span" color={'red'}>
                    *Note:
                  </Typography>{' '}
                  Realm roles are the roles that you define for use in the current realm.
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h6" textAlign="right">
                  Org: {orgId.toUpperCase()}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  label="Search Role by name"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} textAlign="right">
                {accessArray.includes('manage-realm') && (
                  <Button size="medium" variant="contained" onClick={handleClickOpen}>
                    Create Role
                  </Button>
                )}
              </Grid>

              <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>S.No </StyledTableCell>
                        <StyledTableCell sx={{ cursor: 'pointer' }}>Roles </StyledTableCell>
                        {/* <StyledTableCell>Action</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row: any, index) => (
                        <StyledTableRow key={row.SNo}>
                          <StyledTableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.Roles}
                          </StyledTableCell>

                          {/* <StyledTableCell>
                            <Typography sx={{ cursor: 'pointer' }} component="span" color={'red'}>
                              <IconButton
                                color="error"
                                onClick={() => handleOpenConfirmation(row.Roles)}
                                disabled={!accessArray.includes('manage-realm')}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Typography>
                          </StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Roles per Page'}
                />
              </Grid>
            </Grid>
          )}

          {/* modal popup code */}
          <Model
            open={openModal}
            handeleConfirm={handleCreateRole}
            title={'Create Role'}
            saveButtonName={'Create'}
            height="auto"
            data-modal-backdrop="static"
            cancelClick={() => handleClose()}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  label="Create Role"
                  variant="outlined"
                  autoComplete="off"
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                />
              </Grid>
            </Grid>
          </Model>

          <ConfirmationDialog
            open={openConfirmation}
            onClose={() => setOpenConfirmation(false)}
            title="Confirm Deletion"
            content="Are you sure you want to delete this Role?"
            onConfirm={() => handleDeleteRow(selectedUserId)}
          />
        </Layout>
      </Grid>
    </>
  );
}

export default Roles;
