import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import SkeletonLoader from '../SkeletonLoader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { StyledTableCell, StyledTableRow } from '../../styles/tableStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { closeConfModal, openConfModal } from '../../store/appStateSlice';
import Model from '../model';
import { Margin } from '@mui/icons-material';
import { error, success } from '../Toast';
import ConfirmationModal from '../ConfirmationModal';
import { paginationRowsPerPage } from '../../const/strings';
import { pagination } from '../../utils/pagination';
function UserRoleMapping(user: any) {
  const userName = user?.userName;
  const state = useSelector((state: any) => state.config);
  const orgId = useMemo(() => state.orgId, [state.orgId]);
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedRole, setSelectedRole]: any = useState({});
  const [roleMappings, setRoleMappings] = useState([]);
  const [filteredRoleMappings, setFilteredRoleMappings] = useState<any[]>([]);
  const [assignedRoleMappings, setAssignedRoleMappings] = useState<any[]>([]);
  const [completeFetchedClientMAppingRoles, setCompleteFectedClientMappingRoles] = useState<any[]>([]);
  const [filteredAssignedRoleMappings, setFilteredAssignedRoleMappings] = useState<any[]>([]);
  const [selectedRoleMappings, setSelectedRoleMappings] = useState<any[]>([]);
  const [checked, setChecked] = useState(true);
  const [allRoles, setAllRoles] = useState<any[]>([]);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [count1, setCount1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [firstValue1, setFirstValue1] = useState(0);
  const dispatch = useDispatch();
  const [age, setAge] = useState('realm');
  const [openModal, setOpenModal] = useState(false);
  const [filterBy, setFilterBy] = useState('realm');
  const handleClose = () => {
    setOpenModal(false);
    setSearchTermRole('');
    setFilterBy('realm');
    setRowsPerPage1(10);
    setPage1(0);
    setFirstValue1(0);
  };
  const handleChange = (event: any) => {
    let val = event?.target?.value || 'clients';
    setFilterBy(val);
    setAge(val);
    setSearchTermRole('');
    setRowsPerPage1(10);
    setPage1(0);
    setFirstValue1(0);
    getRollMapping('', val, 0, rowsPerPage1, firstValue1);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));
  const getRollMapping = async (query = '', filter = filterBy, page: any, rowsPerPage: any, firstValue1: any) => {
    try {
      const baseUrl = `admin/realms/${orgId}/ui-ext/available-roles/users/${userId}?first=${firstValue1}&max=${rowsPerPage}`;
      const searchUrl = query ? `${baseUrl}&search=${query}` : `${baseUrl}&search=`;
      const realmUrl = `admin/realms/${orgId}/users/${userId}/role-mappings/realm/available?first=0&max=11`;

      if (filter === 'realm') {
        let response = await ApiHttpClient.get(realmUrl);
        const apiData = response.data ? response.data : response;
        setRoleMappings(apiData);
        const paginatedFiltered = pagination(apiData, page, rowsPerPage);
        setCount1(apiData.length);
        setFilteredRoleMappings(paginatedFiltered);
      } else {
        let response = await ApiHttpClient.get(searchUrl);
        const countResponse = await ApiHttpClient.get(
          query
            ? `admin/realms/${orgId}/ui-ext/available-roles/users/${userId}?first=0&max=100&search=${query}`
            : `admin/realms/${orgId}/ui-ext/available-roles/users/${userId}?first=0&max=100&search=`,
        );
        const apiData = response.data ? response.data : response;
        setRoleMappings(apiData);
        setFilteredRoleMappings(apiData);
        setCount1(countResponse.length);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getAssignedRoles = async (checked: Boolean, allRolesData: any[], page: any, rowsPerPage: any) => {
    try {
      const url = `admin/realms/${orgId}/users/${userId}/role-mappings`;
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      const clientMappingsData = apiData.clientMappings ? apiData.clientMappings : {};
      const realmMappingsData = apiData.realmMappings ? apiData.realmMappings : [];
      setCompleteFectedClientMappingRoles(clientMappingsData ? clientMappingsData : []);
      const refactoredClientData =
        clientMappingsData !== undefined && Object.values(clientMappingsData).length > 0
          ? Object.values(apiData.clientMappings).flatMap((ele: any) => {
              const fetchedClientRoles = ele.mappings;
              return fetchedClientRoles.map((role: any) => ({ client: ele?.client, ...role }));
            })
          : [];
      const refactoredData = [...refactoredClientData, ...realmMappingsData];
      if (allRolesData.length > 0) {
        const updatedRefactoredData = [...refactoredData];

        allRolesData.forEach((item: any) => {
          if (!refactoredData.some((ele: any) => ele.name === item.role || ele.name === item.name)) {
            updatedRefactoredData.push({ ...item, disabled: true });
          }
        });

        const filteredData = searchTerm
          ? updatedRefactoredData.filter(
              (roleMapping: any) =>
                new RegExp(searchTerm, 'i').test(roleMapping.name) ||
                new RegExp(searchTerm, 'i').test(roleMapping.role) ||
                new RegExp(searchTerm, 'i').test(roleMapping.description),
            )
          : updatedRefactoredData;
        const paginatedResponse = pagination(searchTerm ? filteredData : updatedRefactoredData, page, rowsPerPage);
        setAssignedRoleMappings(updatedRefactoredData);
        setFilteredAssignedRoleMappings(paginatedResponse);
        setCount(searchTerm ? filteredData.length : updatedRefactoredData.length);
      } else {
        const paginatedResponse = pagination(refactoredData, page, rowsPerPage);
        setAssignedRoleMappings(refactoredData);
        setFilteredAssignedRoleMappings(paginatedResponse);
        setCount(refactoredData.length);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getAssignedRoles(checked, allRoles, page, rowsPerPage);
    getRollMapping(searchTermRole, filterBy, page1, rowsPerPage1, firstValue1);
    // fetchData('');
  }, [orgId]);
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    // fetchData(debouncedTerm);
    searchassignedRoles(debouncedTerm);
  });
  const [searchTermRole, setSearchTermRole] = useDebouncedSearch((debouncedTerm: any) => {
    // fetchData(debouncedTerm);
    if (filterBy === 'realm') {
      searchRoles(debouncedTerm);
    } else {
      getRollMapping(searchTermRole, filterBy, page1, rowsPerPage1, firstValue1);
    }
  });

  const searchRoles = (data: any) => {
    const regexPattern = new RegExp(data, 'i');
    const filtered = roleMappings.filter(
      (roleMapping: any) =>
        regexPattern.test(roleMapping.name) ||
        regexPattern.test(roleMapping.role) ||
        regexPattern.test(roleMapping.description),
    );
    const paginatedFiltered = pagination(filtered, page, rowsPerPage);
    setCount1(filtered.length);
    setFilteredRoleMappings(paginatedFiltered);
  };

  const searchassignedRoles = (data: any) => {
    const regexPattern = new RegExp(data, 'i');

    const filtered =
      assignedRoleMappings.length > 0
        ? assignedRoleMappings.filter(
            (roleMapping: any) =>
              regexPattern.test(roleMapping.name) ||
              regexPattern.test(roleMapping.role) ||
              regexPattern.test(roleMapping.description),
          )
        : [];
    const paginatedFiltered = pagination(filtered, page, rowsPerPage);
    setFilteredAssignedRoleMappings(paginatedFiltered);
    setCount(filtered.length);
  };
  const handleReload = () => {
    // getAssignedRoles(checked, allRoles,);
    setFilterBy('realm');
    getAssignedRoles(checked, allRoles, page, rowsPerPage);
  };
  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setFirstValue(0);
    setPage(0);
    setSearchTerm(value);
  };
  const handleSearchInputChangeRole = (e: any) => {
    const value = e.target.value;
    setFirstValue1(0);
    setPage1(0);
    setSearchTermRole(value);
  };
  const handleUnassignRole = () => {};
  const handleAssignRole = async () => {
    try {
      const promises = selectedRoleMappings.map(async (roleMapping) => {
        const clientId = roleMapping.clientId;
        const url = clientId
          ? `admin/realms/${orgId}/users/${userId}/role-mappings/clients/${clientId}`
          : `admin/realms/${orgId}/users/${userId}/role-mappings/realm`;
        const payload = clientId
          ? [
              {
                id: roleMapping.id,
                name: roleMapping.role,
                description: roleMapping.description,
              },
            ]
          : [roleMapping];
        const response = await ApiHttpClient.post(url, payload);

        if (response.status === 200) {
          return response;
        } else {
          throw new Error(`Error: ${response.statusText}`);
        }
      });

      const finalAssignResult = await Promise.all(promises);
      if (selectedRoleMappings.length > 0) {
        setSearchTerm('');
        handleClose();
        setFilterBy('realm');
        getRollMapping(searchTermRole, filterBy, page1, rowsPerPage1, firstValue1);
        getAssignedRoles(checked, allRoles, page, rowsPerPage);
        setSelectedRoleMappings([]);
        success('User role mapping successfully updated');
      }
    } catch (err: any) {
      setSelectedRoleMappings([]);
      console.error('Error creating User role:', err);
      error('Error While Updating User role mapping');
    }
  };

  const handleCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setChecked(checked);
    setSearchTerm('');
    setPage(0);
    if (!checked) {
      const url = `admin/realms/${orgId}/users/${userId}/role-mappings/realm/composite`;
      const url1 = `/admin/realms/${orgId}/ui-ext/effective-roles/users/${userId}?first=0&max=10&search=`;
      const response = await ApiHttpClient.get(url);
      const response1 = await ApiHttpClient.get(url1);
      const combinedData = [...response, ...response1];
      setAllRoles(combinedData);
      getAssignedRoles(checked, combinedData, 0, rowsPerPage);
      setCount(combinedData.length);
    } else {
      setAllRoles([]);
      getAssignedRoles(checked, [], 0, rowsPerPage);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
    getAssignedRoles(checked, allRoles, newPage, rowsPerPage);
  };

  const handleChangePage1 = (event: any, newPage: any) => {
    const firstValue1 = newPage * rowsPerPage1;
    setFirstValue1(firstValue1);
    setPage1(newPage);
    getRollMapping(searchTermRole, filterBy, newPage, rowsPerPage1, firstValue1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    setFirstValue(0);
    getAssignedRoles(checked, searchTerm ? filteredAssignedRoleMappings : allRoles, 0, rowsPerPage);
  };

  const handleChangeRowsPerPage1 = (event: any) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage1(rowsPerPage);
    setPage1(0);
    setFirstValue1(0);
    getRollMapping(searchTermRole, filterBy, 0, rowsPerPage, 0);
  };

  const handleClickOpen = () => {
    setAge('realm');
    setFilterBy('realm');
    getRollMapping('', filterBy, page1, rowsPerPage1, firstValue1);
    setOpenModal(true);
  };
  const handleClickOpenUnassign = (role: any) => {
    setSelectedRole(role);
    dispatch(openConfModal());
  };
  const handleUnassign = async () => {
    try {
      const selectedId = selectedRole.id;
      const client = selectedRole?.client;
      if (client && client !== undefined) {
        const mappings = completeFetchedClientMAppingRoles[selectedRole?.client]['mappings'];
        const clientId = completeFetchedClientMAppingRoles[selectedRole?.client]['id'];
        const role = mappings?.find((role: any) => role?.id === selectedId);
        if (role && role?.id === selectedId) {
          const payload = [
            {
              name: role.name,
              description: role.description,
              id: role.id,
            },
          ];
        }
        const url = `admin/realms/${orgId}/users/${userId}/role-mappings/clients/${clientId}`;
        const payload: any = [{ id: selectedRole.id, name: selectedRole.name }];
        const response = await ApiHttpClient.delete(url, payload);
      } else {
        const url = `admin/realms/${orgId}/users/${userId}/role-mappings/realm`;
        const payload: any = [{ id: selectedRole.id, name: selectedRole.name }];
        const response = await ApiHttpClient.delete(url, payload);
      }
      setSearchTerm('');
      getAssignedRoles(checked, allRoles, page, rowsPerPage);
      setFilterBy('realm');
      getRollMapping(searchTermRole, filterBy, page1, rowsPerPage1, firstValue1);
      dispatch(closeConfModal());
      success('Scope mapping successfully removed');
    } catch (err: any) {
      console.error('Error creating group:', err);
      error(err?.['response']?.data || 'Error Updaing Scope mapping');
    }
  };
  const handleCheckbox = (event: any, role: any) => {
    if (event.target.checked) {
      // Add the role to selectedRoleMappings
      setSelectedRoleMappings((prev: any) => [...prev, role]);
    } else {
      // Remove the role from selectedRoleMappings
      setSelectedRoleMappings((prev: any) => prev.filter((selectedRole: any) => selectedRole.id !== role.id));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <>
            {assignedRoleMappings?.length === 0 || assignedRoleMappings === undefined ? (
              <Grid item xs={12} sm={6}>
                <>
                  <Typography variant="body1" gutterBottom component="div">
                    No roles for this group
                  </Typography>
                  <Typography variant="body2" gutterBottom component="div">
                    You haven't created any roles for this group. Create a role to get started.
                  </Typography>
                </>

                <Button
                  disabled={!accessArray.includes('manage-users')}
                  onClick={handleClickOpen}
                  variant="contained"
                  color="primary"
                >
                  Assign Role
                </Button>
              </Grid>
            ) : (
              <>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      value={searchTerm}
                      label="Search By Role Name"
                      variant="outlined"
                      autoComplete="off"
                      onChange={handleSearchInputChange}
                    />
                  </Grid>
                  <Grid item xs={8} display={'flex'} alignItems={'center'}>
                    <Checkbox
                      checked={checked}
                      onChange={handleCheck}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant="body1" gutterBottom>
                      Hide inherited roles
                    </Typography>
                    <Button
                      disabled={!accessArray.includes('manage-users')}
                      onClick={handleClickOpen}
                      variant="contained"
                      sx={{ margin: '0px 14px' }}
                    >
                      Assign Role
                    </Button>
                    <Button
                      disabled={!accessArray.includes('manage-users')}
                      onClick={handleReload}
                      startIcon={<CachedIcon />}
                    >
                      Reload
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                      <Table sx={{ minWidth: 700 }}>
                        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                          <TableRow>
                            <StyledTableCell>Sno</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Inherited</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={5}>Loading...</TableCell>
                            </TableRow>
                          ) : (
                            filteredAssignedRoleMappings?.map((role: any, index: any) => (
                              <StyledTableRow key={role.id}>
                                <StyledTableCell component="th" scope="row">
                                  {page * rowsPerPage + index + 1}
                                  {/* <Checkbox
                              // onChange={(event: any) => handleCheckbox(event, role)}
                              // checked={selectedRoleMappings.some((selectedRole: any) => selectedRole.id === role.id)}
                              /> */}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {role.name || role.role}
                                    {role.client ? (
                                      <Chip
                                        label={role.client}
                                        sx={{
                                          margin: ' 0px 9px',
                                          border: '1px solid #868686 !important',
                                          background: '#d3d3d33b',
                                          height: '23px',
                                        }}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell>{role.description}</StyledTableCell>
                                <StyledTableCell>{role.clientRole}</StyledTableCell>
                                <StyledTableCell>
                                  <Button
                                    disabled={!accessArray.includes('manage-users')}
                                    onClick={() => handleClickOpenUnassign(role)}
                                  >
                                    Unassign
                                  </Button>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                      rowsPerPageOptions={paginationRowsPerPage}
                      labelRowsPerPage={'Roles per Page'}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </Grid>
      </Box>

      <Model
        open={openModal}
        handeleConfirm={handleAssignRole}
        width="1024px"
        height="auto"
        title={`Assign roles to ${userName}`}
        saveButtonName={'Assign'}
        data-modal-backdrop="static"
        cancelClick={() => handleClose()}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <Select value={age} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value={'clients'}>Filter by clients</MenuItem>
                <MenuItem value={'realm'}>Filter by realm roles</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              value={searchTermRole}
              label="Search By Role Name"
              variant="outlined"
              autoComplete="off"
              onChange={handleSearchInputChangeRole}
            />
          </Grid>
          <Grid item xs={4}>
            {/* <Button onClick={handleReload} startIcon={<CachedIcon />}>
              {' '}
              Reload
            </Button> */}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {filteredRoleMappings && filteredRoleMappings.length > 0 ? (
            <>
              <Grid sx={{ marginTop: 2 }} item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>{/* <Checkbox sx={{ color: 'white' }} /> */}</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={5}>Loading...</TableCell>
                        </TableRow>
                      ) : (
                        filteredRoleMappings?.map((role: any, index: any) => (
                          <StyledTableRow key={role.id}>
                            <StyledTableCell component="th" scope="row">
                              <Checkbox
                                onChange={(event: any) => handleCheckbox(event, role)}
                                checked={selectedRoleMappings.some((selectedRole: any) => selectedRole.id === role.id)}
                              />
                            </StyledTableCell>
                            <StyledTableCell>{role.name || role.role}</StyledTableCell>
                            <StyledTableCell>{role.description}</StyledTableCell>
                          </StyledTableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count1}
                  page={page1}
                  onPageChange={handleChangePage1}
                  rowsPerPage={rowsPerPage1}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage1(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Roles per Page'}
                />
              </Grid>
            </>
          ) : (
            <Grid container spacing={3} sx={{ margin: 3 }} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} container direction="column" alignItems="center" justifyContent="center">
                <Typography variant="body1" gutterBottom>
                  No roles for this user
                </Typography>
                <Typography variant="body2" gutterBottom>
                  There are no realm roles to assign
                </Typography>
                <Button onClick={handleChange} color="primary">
                  Filter By Clients
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Model>

      <ConfirmationModal
        handeleConfirm={handleUnassign}
        width="auto"
        height="auto"
        title={'Remove role?'}
        saveButtonName={'Remove'}
        data-modal-backdrop="static"
      >
        <Grid container spacing={2}>
          <Typography sx={{ margin: 2 }} variant="body1" gutterBottom>
            Are you sure you want to remove this role?
          </Typography>
        </Grid>
      </ConfirmationModal>
    </>
  );
}

export default UserRoleMapping;
