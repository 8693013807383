import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';

import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SkeletonLoader from '../SkeletonLoader';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useSelector } from 'react-redux';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { error, success } from '../Toast';
import { pagination } from '../../utils/pagination';
import { paginationRowsPerPage } from '../../const/strings';
import { useParams } from 'react-router-dom';
import Model from '../model';

function ClientsScope({ client }: any) {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const clientState = useSelector((state: any) => state.client);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [scopes, SetClientScopes] = useState([]);
  const [defaultclient, SetDefaultClient] = useState([]);
  const [optionalclient, SetOptionalClient] = useState([]);
  const { clientId } = useParams();
  const initialRender = useRef(true);
  const [age, setAge] = useState('Name');
  const [selectedProtocol, setSelectedProtocol] = useState('All');
  const [filterBy, setFilterBy] = useState('Name');
  const [openModal, setOpenModal] = useState(false);
  const [filteredScopes, setFilteredScopes] = useState<any>([]);
  const [defaultScopes, setDefaultScopes] = useState<any>([]);
  const [optionalScopes, setOptionalScopes] = useState<any>([]);
  const [selectedScopes, setSelectedScopes] = useState<any[]>([]);
  const [unassignedScopes, setUnassignedScopes] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  const [searchUnassignedTerm, setSearchUnassignedTerm] = useDebouncedSearch((debouncedTerm: any) => {
    filterSearchUnassignedScopes(debouncedTerm);
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const fetchData = async (query = '') => {
    try {
      const url2 = `admin/realms/${orgId}/clients/${clientId}/default-client-scopes`;
      const response2 = await ApiHttpClient.get(url2);
      const clientData: any = response2.map((ele: any, ind: any) => ({ ...ele, key: 'default' }));
      const url1 = `admin/realms/${orgId}/clients/${clientId}/optional-client-scopes`;
      const response1 = await ApiHttpClient.get(url1);
      const optionalClient: any = response1.map((ele: any) => ({ ...ele, key: 'optional' }));
      const url = query
        ? `/admin/realms/${orgId}/client-scopes?search=${query}`
        : `/admin/realms/${orgId}/client-scopes?`;

      const response = await ApiHttpClient.get(url);
      setDefaultScopes(response2);
      setOptionalScopes(response1);
      const filteredScopesArray: any = [...response1, ...response2];

      const { matchingScopes, remainingScopes } = response.reduce(
        (acc: any, item: any) => {
          const matchedItem = filteredScopesArray.find((mainItem: any) => mainItem.name === item.name);
          if (matchedItem) {
            acc.matchingScopes.push(item);
          } else {
            if (item.protocol !== 'saml') {
              acc.remainingScopes.push(item);
            }
          }
          return acc;
        },
        { matchingScopes: [], remainingScopes: [] },
      );

      setFilteredScopes(remainingScopes);
      const filteredValues = matchingScopes.filter(
        (item: any) =>
          item?.name.toLowerCase().includes(query.toLowerCase()) ||
          item?.description.toLowerCase().includes(query.toLowerCase()) ||
          item?.protocol.toLowerCase().includes(query.toLowerCase()),
      );
      setUnassignedScopes(remainingScopes);
      const paginatedResponse = pagination(query ? filteredValues : matchingScopes, page, rowsPerPage);
      query ? setCount(filteredValues.length) : setCount(matchingScopes.length);
      let apiData: any = paginatedResponse;
      let totalData = paginatedResponse.map((ele: any) => {
        let isDefault = clientData.find((item: any) => item.id === ele.id);
        let isOptional = optionalClient.find((item: any) => item.id === ele.id);
        return {
          ...ele,
          key: isDefault ? 'default' : isOptional ? 'optional' : 'none',
        };
      });
      const totalClientData: any = [...optionalClient, ...clientData];
      totalClientData.length > 0 ? SetClientScopes(totalData) : SetClientScopes(apiData);
      SetOptionalClient(optionalClient);
      SetDefaultClient(clientData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSearchType = (e: any) => {
    const {
      target: { value },
    } = e;
    setAge(value);
    setSelectedProtocol('All');
    setUnassignedScopes(filteredScopes);
    setSearchUnassignedTerm('');
  };

  const handleProtocolTypeChange = (e: any) => {
    const {
      target: { value },
    } = e;
    setSelectedProtocol(value);
    if (value !== 'All') {
      let filteredUnassignedScopes =
        filteredScopes.length > 0
          ? filteredScopes.filter((ele: any) => ele?.protocol.toLowerCase() === value.toLowerCase())
          : unassignedScopes;
      setUnassignedScopes(filteredUnassignedScopes);
    } else {
      setUnassignedScopes(filteredScopes);
    }
  };

  const handleChange = async (e: any, obj: any) => {
    const value = e.target.value;
    setAge(value);
    if (value.toLowerCase() !== obj.key) {
      try {
        const deleteUrl =
          value.toLowerCase() === 'optional'
            ? `/admin/realms/${orgId}/clients/${clientId}/default-client-scopes/${obj.id}`
            : `/admin/realms/${orgId}/clients/${clientId}/optional-client-scopes/${obj.id}`;

        const updateUrl =
          value.toLowerCase() === 'optional'
            ? `/admin/realms/${orgId}/clients/${clientId}/optional-client-scopes/${obj.id}`
            : `/admin/realms/${orgId}/clients/${clientId}/default-client-scopes/${obj.id}`;

        await ApiHttpClient.delete(deleteUrl, {});
        await ApiHttpClient.put(updateUrl, {});

        const updatedScopes: any = scopes.map((scope: any) =>
          scope.id === obj.id ? { ...scope, key: value.toLowerCase() } : scope,
        );
        SetClientScopes(updatedScopes);
        success('Scope mapping updated');
      } catch (err) {
        console.error('Error changing assignment type:', err);
        error('Error in Scope mapping');
      }
    }
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };

  const handleClickOpen = () => {
    setFilterBy('realm');
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    // setSearchTermRole('');
    setFilterBy('proctocol');
    setAge('Name');
    setSearchUnassignedTerm('');
    setSelectedProtocol('Add');
    fetchData(searchTerm);
    // setRowsPerPage1(10);
    // setPage1(0);
    // setFirstValue1(0);
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };
  const handleDeleteClientScope = async (scopeId: any) => {
    try {
      const findTypeOfScope = defaultScopes.find((e: any) => e.id === scopeId) ? 'default' : 'optional';
      const url =
        findTypeOfScope === 'default'
          ? `/admin/realms/${orgId}/clients/${clientId}/default-client-scopes/${scopeId}`
          : `/admin/realms/${orgId}/clients/${clientId}/optional-client-scopes/${scopeId}`;
      await ApiHttpClient.delete(url, {});
      success('Scope deleted successfully');
      fetchData(searchTerm);
    } catch (err) {
      console.log('error::-', err);
      error('Error in deleting the scope');
    }
  };
  const handleAssignScope = async (value: any) => {
    const assignPromises = selectedScopes.map(async (scope: any) => {
      const url =
        value === 'default'
          ? `/admin/realms/${orgId}/clients/${clientId}/default-client-scopes/${scope.id}`
          : `/admin/realms/${orgId}/clients/${clientId}/optional-client-scopes/${scope.id}`;

      return ApiHttpClient.put(url, {});
    });
    const result = await Promise.all(assignPromises);
    if (result.length > 0) {
      fetchData(searchTerm);
      success('Scope Mapping Updated');
      setOpenModal(false);
      setSelectedScopes([]);
    }
  };

  const handleSearchByType = (event: any) => {
    const value = event.target.value;
    setSearchUnassignedTerm(value);
    filterSearchUnassignedScopes(value);
  };

  const filterSearchUnassignedScopes = (value: any) => {
    if (value) {
      const filteredScopesAfterSearch =
        filteredScopes.length > 0
          ? filteredScopes.filter((scope: any) => {
              const name = scope?.name.toLowerCase();
              if (name.includes(value.toLowerCase())) {
                return scope;
              }
            })
          : filteredScopes;
      setUnassignedScopes(filteredScopesAfterSearch);
    } else {
      setUnassignedScopes(filteredScopes);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  const handleSelectAllClickTable = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      const newSelected = unassignedScopes.map((user: any) => user);
      setSelectedScopes(newSelected);
      return;
    }
    setSelectedScopes([]);
  };

  const handleCheckbox = (event: any, role: any) => {
    if (event.target.checked) {
      setSelectedScopes((prev: any) => [...prev, role]);
    } else {
      setSelectedScopes((prev: any) => prev.filter((selectedRole: any) => selectedRole.id !== role.id));
    }
  };

  return (
    <>
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={4} sm={6}>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </Grid>
          <Grid item xs={4} sm={6} textAlign="right">
            <Button size="medium" variant="contained" onClick={handleClickOpen}>
              Add client scope
            </Button>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    <StyledTableCell sx={{ width: '5%' }}>S.No</StyledTableCell>
                    <StyledTableCell sx={{ width: '10%' }}>Name</StyledTableCell>
                    <StyledTableCell sx={{ width: '15%', whiteSpace: 'nowrap' }}>Assigned type</StyledTableCell>
                    <StyledTableCell sx={{ width: '20%' }}>Protocol</StyledTableCell>
                    <StyledTableCell sx={{ width: '40%', whiteSpace: 'nowrap' }}>Description</StyledTableCell>
                    {/* <StyledTableCell sx={{ width: '10%' }}>Action</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scopes.map((scopes: any, index: any) => (
                    <StyledTableRow key={scopes.id}>
                      <StyledTableCell component="th" scope="row">
                        {page * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {scopes.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Select
                          size="small"
                          fullWidth
                          value={scopes.key}
                          disabled={!accessArray.includes('manage-clients')}
                          onChange={(e: any) => handleChange(e, scopes)}
                        >
                          <MenuItem value="default">Default</MenuItem>
                          <MenuItem value="optional">Optional</MenuItem>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {scopes.protocol}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {scopes.description}
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        <Typography sx={{ cursor: 'pointer' }} component="span" color={'red'}>
                          <IconButton
                            disabled={!accessArray.includes('manage-clients')}
                            color="error"
                            onClick={() => handleDeleteClientScope(scopes.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Typography>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
              rowsPerPageOptions={paginationRowsPerPage}
              labelRowsPerPage={'Scopes per Page'}
            />
          </Grid>

          <Model
            open={openModal}
            handeleConfirm={handleAssignScope}
            width="1024px"
            height="auto"
            title={`Add client scopes to ${client}`}
            saveButtonName={'Add'}
            data-modal-backdrop="static"
            cancelClick={() => handleClose()}
            showDropdown={true}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth size="small">
                  <Select
                    value={age}
                    onChange={handleSearchType}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={'Name'}>Name</MenuItem>
                    <MenuItem value={'protocol'}>Protocol</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {age === 'Name' ? (
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-basic"
                    value={searchUnassignedTerm}
                    label="Search for client scope"
                    variant="outlined"
                    autoComplete="off"
                    onChange={handleSearchByType}
                  />
                ) : (
                  <FormControl fullWidth size="small">
                    <Select
                      value={selectedProtocol}
                      onChange={handleProtocolTypeChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      <MenuItem value={'openid-connect'}>OpenID Connect</MenuItem>
                      <MenuItem value={'SAML'}>SAML</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={4}>
                {/* <Button onClick={handleReload} startIcon={<CachedIcon />}>
              {' '}
              Reload
            </Button> */}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {unassignedScopes && unassignedScopes.length > 0 ? (
                <>
                  <Grid sx={{ marginTop: 2 }} item xs={12}>
                    <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                      <Table sx={{ minWidth: 700 }}>
                        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                          <TableRow>
                            <StyledTableCell>
                              <Checkbox
                                sx={{ color: 'white' }}
                                checked={
                                  unassignedScopes.length > 0 && selectedScopes.length === unassignedScopes.length
                                }
                                onChange={handleSelectAllClickTable}
                              />
                            </StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Protocol</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={5}>Loading...</TableCell>
                            </TableRow>
                          ) : (
                            unassignedScopes?.map((role: any, index: any) => (
                              <StyledTableRow key={role.id}>
                                <StyledTableCell component="th" scope="row">
                                  <Checkbox
                                    onChange={(event: any) => handleCheckbox(event, role)}
                                    checked={selectedScopes.some((selectedRole: any) => selectedRole.id === role.id)}
                                  />
                                </StyledTableCell>
                                <StyledTableCell>{role.name || role.role}</StyledTableCell>
                                <StyledTableCell>{role.protocol}</StyledTableCell>
                                <StyledTableCell>{role.description}</StyledTableCell>
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                      component="div"
                      count={count1}
                      page={page1}
                      onPageChange={handleChangePage1}
                      rowsPerPage={rowsPerPage1}
                      onRowsPerPageChange={(event) => handleChangeRowsPerPage1(event)}
                      rowsPerPageOptions={paginationRowsPerPage}
                      labelRowsPerPage={'Roles per Page'}
                    /> */}
                  </Grid>
                </>
              ) : (
                <Grid container spacing={3} sx={{ margin: 3 }} justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={6} container direction="column" alignItems="center" justifyContent="center">
                    <Typography variant="body1" gutterBottom>
                      No client scopes
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      There are no client scopes left to add
                    </Typography>
                    {/* <Button onClick={handleChange} color="primary">
                      Filter By Clients
                    </Button> */}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Model>
        </Grid>
      )}
    </>
  );
}

export default ClientsScope;
